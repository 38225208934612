<template>
  <el-select
    v-model="spotTypesModel"
    @change="spotTypesChanged"
    autocomplete="new-password"
    collapse-tags
    :multiple="true"
    :placeholder="placeholder ?? $t('COMMON.TYPE')"
    :filterable="filterable"
    :disabled="disabled"
    remote
    :remote-method="getTypes"
    :loading="loading"
    :allow-create="allowCreate"
  >
    <el-option v-if="showAll" :value="null" :label="$t('COMMON.ALL')">
    </el-option>
    <el-option v-if="allowNone" :value="null" :label="$t('COMMON.NONE')">
    </el-option>
    <el-option
      v-for="spotType in spotTypesList"
      :key="spotType.id"
      :value="spotType.id"
      :label="spotType.name"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";

export default {
  name: "spot-types-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    allowCreate: {
      type: Boolean,
      default: false,
      description: "Allow tag creation",
    },
    showAll: {
      type: Boolean,
      default: true,
      description: "Show the option all",
    },
    allowNone: {
      type: Boolean,
      default: false,
      description: "Show the option none",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },

    spotTypes: {
      type: Array,
      default: null,
      description: "Spot type array",
    },
    filterSpotCategory: {
      type: String,
      default: null,
      description: "SpotCategory id",
    },
    placeholder: {
      type: String,
      default: null,
      description: "Placeholder",
    },
  },

  data() {
    return {
      spotTypesModel: this.spotTypes.map((item) => item.id),
      spotTypesList: {},
    };
  },

  setup() {},

  created() {
    this.getTypes(null, this.spotType);
  },

  methods: {
    async getTypes(query = null, id = null) {
      try {
        this.loading = true;
        let params = {
          sort: "name",
          filter: {
            ...(query ? { search: query } : {}),
            ...(id ? { id: id } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        if (this.filterSpotCategory) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              in_category: this.filterSpotCategory,
            },
          };
        }

        await this.$store.dispatch("spotTypes/list", params);
        const spotTypesArr = await this.$store.getters["spotTypes/list"];
        this.spotTypesList = {};
        spotTypesArr.forEach((spotType) => {
          this.spotTypesList[spotType.id] = spotType;
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    spotTypesChanged(spotTypes) {
      this.$emit(
        "spotTypesChanged",
        spotTypes.map((item) => {
          return { id: item, type: "spot-types" };
        })
      );
    },
  },

  watch: {
    spotTypes(spotType) {
      if (spotType) {
        this.spotTypeModel = spotType;
        if (spotType !== this.spotTypeModel) {
          this.getTypes(null, spotType);
        }
      } else {
        this.spotTypeModel = null;
      }
    },
    filterSpotCategory() {
      this.getTypes();
    },
  },
};
</script>
