<template>
  <el-select
    v-model="pricingRuleModel"
    @change="pricingRuleChanged"
    autocomplete="new-password"
    collapse-tags
    :placeholder="placeholder ?? $t('COMMON.TYPE')"
    :filterable="filterable"
    :multiple="multiple"
    :disabled="disabled"
    remote
    :remote-method="getPricingRules"
    :loading="loading"
    :allow-create="allowCreate"
  >
    <el-option v-if="showAll" :value="null" :label="$t('COMMON.ALL')">
    </el-option>
    <el-option v-if="allowNone" :value="null" :label="$t('COMMON.NONE')">
    </el-option>
    <el-option
      v-for="pricingRule in pricingRules"
      :key="pricingRule.id"
      :value="pricingRule.id"
      :label="pricingRule.name"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";

export default {
  name: "pricing-rule-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    allowCreate: {
      type: Boolean,
      default: false,
      description: "Allow tag creation",
    },
    showAll: {
      type: Boolean,
      default: true,
      description: "Show the option all",
    },
    allowNone: {
      type: Boolean,
      default: false,
      description: "Show the option none",
    },
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    multiple: {
      type: Boolean,
      default: false,
      description: "Can filter",
    },
    pricingRule: {
      type: String,
      default: null,
      description: "Pricing Rule id",
    },

    placeholder: {
      type: String,
      default: null,
      description: "Placeholder",
    },
  },

  data() {
    return {
      pricingRuleModel: this.pricingRule,
      pricingRules: {},
    };
  },

  setup() {},

  created() {
    this.getPricingRules(null, this.pricingRule);
  },

  methods: {
    async getPricingRules(query = null, id = null) {
      try {
        this.loading = true;
        let params = {
          sort: "name",
          filter: {
            ...(query ? { search: query } : {}),
            ...(id ? { id: id } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };
        if (this.filterOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.filterOrganization,
            },
          };
        }

        await this.$store.dispatch("pricingRules/list", params);
        const pricingRulesArr = await this.$store.getters["pricingRules/list"];
        this.pricingRules = {};
        pricingRulesArr.forEach((pricingRule) => {
          this.pricingRules[pricingRule.id] = pricingRule;
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    pricingRuleChanged(pricingRule) {
      this.$emit("pricingRuleChanged", pricingRule);
    },
  },

  watch: {
    pricingRule(pricingRule) {
      if (pricingRule) {
        this.pricingRuleModel = pricingRule;
        if (pricingRule !== this.pricingRuleModel) {
          this.getPricingRules(null, pricingRule);
        }
      } else {
        this.pricingRuleModel = null;
      }
    },
    filterOrganization(filterOrganization) {
      this.getPricingRules(null, this.pricingRule);
    },
  },
};
</script>
